<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-16 flex-row justify-space-between">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>
				<div class="pa-20 size-px-16 ">
					<div class="flex-row justify-space-between">
						<button
							:class="{'btn-primary': item.payment_mthd_div_code == 'PM00100001'}"
							class="flex-1 btn btn-primary-outline radius-l-20 pa-10"
							@click="item.payment_mthd_div_code = 'PM00100001'"
						>{{ $language.common.personal}}</button>
						<button
							:class="{'btn-primary': item.payment_mthd_div_code == 'PM00100002'}"
							class="flex-1 btn btn-primary-outline radius-r-20 pa-10"
							@click="item.payment_mthd_div_code = 'PM00100002'"
						>{{ $language.common.corporation}}</button>
					</div>
					<div class="mt-20">
						<label
						>{{  $language.mypage.credit.credit_card_number }}</label>
						<div class="flex-row justify-center mt-10 box radius-20 pa-10">
							<input
								v-model="item.card_number[0]"
								placeholder="0000"
								class="flex-1 width-auto text-center"
								type="number"
								:rules="$rules.numeric(item.card_number, 0, 4)"
								@keyup="onFocus($event, 4, 'card_number_1')"
							/>
							<input
								v-model="item.card_number[1]"
								placeholder="0000"
								class="flex-1 width-auto text-center"
								ref="card_number_1"
								type="password"
								:rules="$rules.numeric(item.card_number, 1, 4)"
								@keyup="onFocus($event, 4, 'card_number_2')"
							/>
							<input
								v-model="item.card_number[2]"
								placeholder="0000"
								class="flex-1 width-auto text-center"
								ref="card_number_2"
								type="password"
								:rules="$rules.numeric(item.card_number, 2, 4)"
								@keyup="onFocus($event, 4, 'card_number_3')"
							/>
							<input
								v-model="item.card_number[3]"
								placeholder="0000"
								class="flex-1 width-auto text-center"
								ref="card_number_3"
								type="number"
								:rules="$rules.numeric(item.card_number, 3, 4)"
								@keyup="onFocus($event, 4, 'valid_term_year')"
							/>
						</div>
					</div>
					<div class="mt-20">
						<div>
							<label
							>{{  $language.mypage.credit.credit_card_validity }}</label>
							<div class="mt-10 flex-row justify-space-betweens ">
								<input
									v-model="item.valid_term_mm"
									:rules="$rules.numeric(item, 'valid_term_mm', 2)"
									class="input-box text-center"
									ref="valid_term_mm"
									placeholder="mm"
									@keyup="onFocus($event, 2, 'valid_cvc')"
								>
								<input
									v-model="item.valid_term_year"
									:rules="$rules.numeric(item, 'valid_term_year', 2)"
									class="input-box mr-10 text-center"
									ref="valid_term_year"
									placeholder="yy"
									@keyup="onFocus($event, 2, 'valid_term_mm')"
								>
							</div>
						</div>
					</div>
					<div class="mt-20 flex-row justify-space-between">
						<div class="flex-1 mr-10">
							<label
							>{{  $language.mypage.credit.credit_card_cvc }}</label>
							<input
								v-model="item.valid_cvc"
								class="mt-10 input-box text-center"
								:placeholder="$language.mypage.credit.credit_regist_txt_cvc"
								ref="valid_cvc"
								:rules="$rules.numeric(item, 'valid_cvc', 3)"
								@keyup="onFocus($event, 3, 'paswd')"
							>
						</div>
						<div class="flex-1">
							<label
							>{{  $language.mypage.credit.credit_card_pass }}</label>
							<input
								v-model="item.paswd"
								type="password"
								class="mt-10 input-box text-center"
								:placeholder="$language.mypage.credit.credit_regist_txt_pass"
								ref="paswd"
								:rules="$rules.numeric(item, 'paswd', 2)"
								@keyup="onFocus($event, 2, 'personal_biz_certfc_info')"
							>
						</div>
					</div>
					<div
						v-if="item.payment_mthd_div_code == 'PM00100001'"
						class="mt-20"
					>
						<label
						>{{  $language.mypage.credit.credit_birthym }}</label>
						<input
							v-model="item.personal_biz_certfc_info"
							type="password"
							class="mt-10 input-box"
							ref="personal_biz_certfc_info"
							:placeholder="$language.mypage.credit.credit_regist_txt_birth"
							:rules="$rules.numeric(item, 'personal_biz_certfc_info', 6)"
						>
					</div>
					<div
						v-else
						class="mt-20"
					>
						<label
						>{{  $language.mypage.credit.credit_business_number }}</label>
						<input
							v-model="item.personal_biz_certfc_info"
							type="password"
							class="mt-10 input-box"
							ref="personal_biz_certfc_info"
							:placeholder="$language.mypage.credit.credit_regist_txt_corporation"
							:rules="$rules.numeric(item, 'personal_biz_certfc_info', 10)"
						>
					</div>
				</div>

				<div class="text-right mt-10 prl-20">
					<button
						@click="toList"
						class="btn-inline btn-primary radius-20 mr-10"
					>목록</button>
					<button
						@click="postCreditSave"
						class="btn-inline btn-primary radius-20"
					>저장</button>
				</div>
			</div>
		</div>

		<PopupLayer
			v-if="is_result"
		>
			<template
				v-slot:body
			>
				<mafia-credit-result
					:user="user"
					:type="result_type"

					@click="toList"
					@cancel="toCancel"

					class="bg-white width-480 radius-20 ma-auto"
				></mafia-credit-result>
			</template>
		</PopupLayer>
	</div>
</template>


<script>
import MafiaCreditResult from "@/view/Mypage/mafia-credit-result";
import MypageSide from "@/view/Mypage/mypage-lnb";
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafiaCredit'
	, components: {PopupLayer, MypageSide, MafiaCreditResult}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'mafiaCreditRegist'
				, title: this.$language.mypage.credit_regist
				, not_footer: true
				, not_header: false
				, type: 'mypage'
				, from: 'mafiaCredit'
				, is_save: true
				, callBack: {
					name: 'save_credit'
				}
				, bg_contents: 'bg-gray01'
			}
			, item: {
				card_number: []
				, payment_mthd_div_code: 'PM00100001'
			}
			, is_result: false
			, result_type: false
		}
	}
	, computed: {
		card_number: function(){
			let t = this.item.card_number.join('')

			return t
		}
	}
	, methods: {
		postCreditSave: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_credit
					, data: {
						member_number: this.user.member_number
						, payment_mthd_div_code: this.item.payment_mthd_div_code
						, card_number: this.card_number
						, valid_term_year: this.item.valid_term_year
						, valid_term_mm: this.item.valid_term_mm
						, paswd: this.item.paswd
						, personal_biz_certfc_info: this.item.personal_biz_certfc_info
					}
					, type: true
				})
				if(result.success){
					this.$bus.$off('callBack')
					this.result_type = true
				}else{
					this.result_type = false
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				// this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_result = true
				this.$bus.$emit('on', false)
			}
		}

		, onFocus: function(e, max, target){

			if(e.target.value.length >= max){
				this.$refs[target].focus()
			}
		}
		, toList: function(){
			this.$emit('goBack')
		}
		, toCancel: function(){
			this.is_result = false
			this.$bus.$emit('onLoad', this.program)
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>